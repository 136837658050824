var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Home"},[(_vm.$store.state.platform == 'pc')?_c('BannerPc',{attrs:{"data":_vm.ad}}):_c('BannerMob',{attrs:{"data":_vm.ad}}),_c('div',{staticClass:"hot"},[_c('div',{staticClass:"line1"},[_c('div',{staticClass:"left"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic1}})]),_c('div',{staticClass:"right"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic2}})])]),_c('div',{staticClass:"line2"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic3}})])]),_vm._m(0),_c('div',{staticClass:"selected"},_vm._l((_vm.recommand),function(val,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"pic",on:{"click":function($event){return _vm.$router.push(
              `/${_vm.$store.state.platform == 'pc' ? 'GoodsDetail' : 'Detail_Mobile'}?id=${
                val.spuId
              }`
            )}}},[_c('img',{staticClass:"regular",attrs:{"src":val.cover}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(val.spuName))]),_c('div',{staticClass:"price"},[_vm._v("€"+_vm._s(val.price))]),_c('div',{staticClass:"addCart",on:{"click":function($event){return _vm.handleAddCart(val)}}},[_vm._v(" "+_vm._s(_vm.$t("goodDetail.addCart"))+" ")])])}),0),_c('div',{staticClass:"recien"},[_c('div',{staticClass:"title"},[_vm._v("Recien nacido")]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"line1"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic4}})]),_c('div',{staticClass:"line2"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"pic"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic5}})]),_c('div',{staticClass:"sex"},[_vm._v("Niña")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"pic"},[_c('img',{staticClass:"regular",attrs:{"src":_vm.pics.pic6}})]),_c('div',{staticClass:"sex"},[_vm._v("Niño")])])])])]),_c('div',{on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_c('el-dialog',{attrs:{"title":"Talla","visible":_vm.listVisible,"width":"30%","before-close":(done) => {
            done();
          }},on:{"update:visible":function($event){_vm.listVisible=$event}}},[_c('div',{staticClass:"list-content"},_vm._l((_vm.skus),function(item,index){return _c('p',{key:index,staticClass:"list-sku",on:{"click":function($event){$event.stopPropagation();return (() => {
                _vm.$bus.$emit('addCart', item.sku_id);
                _vm.listVisible = false;
              }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.spec_info.replace(/Talla:/g, ""))+" ")])}),0)])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selTitle"},[_c('div',{staticClass:"text"},[_vm._v("Favorites of"),_c('br'),_vm._v(" the week")])])
}]

export { render, staticRenderFns }